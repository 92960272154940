import React, { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { config } from 'data';
import { authService, profileService } from 'services';
import { useQuery, useQueryInvalidate } from 'hooks';
import { AuthEvent } from 'types/services';

type ContextValue = {
  initialized: boolean;
  authenticated: boolean;
};

type ProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<ContextValue>({
  initialized: false,
  authenticated: false,
});

const AuthProvider: FC<ProviderProps> = ({ children }) => {
  const queryInvalidate = useQueryInvalidate();

  const [initialized, setInitialized] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  useQuery({
    queryKey: [config.PROFILE_QUERY_KEY],
    queryFn: profileService.getProfile,
    enabled: authenticated,
    refetchInterval: config.AUTH_KEEP_ALIVE_INTERVAL,
    refetchIntervalInBackground: true,
    showMessage: false,
    showNotification: false,
  });

  const invalidateProfileQueries = useCallback(async () => {
    await queryInvalidate([config.PROFILE_QUERY_KEY]);
  }, [queryInvalidate]);

  const updateAuthenticatedState = useCallback(() => {
    const authenticated = Boolean(authService.getAccessToken());

    setAuthenticated(authenticated);

    if (authenticated) {
      invalidateProfileQueries();
    }
  }, [invalidateProfileQueries]);

  useEffect(() => {
    updateAuthenticatedState();

    authService.on(AuthEvent.CREDENTIALS, updateAuthenticatedState);

    setInitialized(true);

    return () => {
      authService.off(AuthEvent.CREDENTIALS, updateAuthenticatedState);
    };
  }, [updateAuthenticatedState]);

  const value = useMemo(() => ({
    initialized,
    authenticated,
  }), [initialized, authenticated]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

const Auth = {
  Context: AuthContext,
  Provider: AuthProvider,
};

export default Auth;
