import { Nullable, Uuid } from './common';

export enum ActivityStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum AuditLogAction {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum AuditLogObject {
  CLIENT = 'client',
  COST = 'cost',
  CURRENCY = 'currency',
  CURRENCY_RATE = 'currency-rate',
  FEE_COMMISSION = 'fee-commission',
  FEE_GROUP = 'fee-group',
  PROVIDER = 'provider',
}

export enum BusinessAccountApiStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum BusinessAccountRoleId {
  'OWNER' = 'ROLE_OWNER',
  'ACCOUNTANT' = 'ROLE_ACCOUNTANT',
  'ANALYST' = 'ROLE_ANALYST',
}

export enum BusinessAccountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum BusinessAccountType {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum ClientStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum FeeCommissionLevel {
  GLOBAL = 'GLOBAL',
  GROUP = 'GROUP',
  CLIENT = 'CLIENT',
}

export enum ProviderFrequency {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
}

export enum TransactionFeeType {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
}

export enum TransactionStatus {
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  FAILED = 'failed',
  INSUFFICIENT_FUNDS = 'insufficient-funds',
  PENDING = 'pending',
}

export enum TransactionType {
  TRANSFERS = 'transfers',
  TOP_UPS = 'top-ups',
}

export enum UserRoleId {
  ADMIN = 'ROLE_SUPER_ADMIN',
  ANALYST = 'ROLE_ANALYST_ADMIN',
  VIEWER = 'ROLE_VIEWER_ADMIN',
}

type Model<Params> = Params & {
  id: Uuid;
  createdAt: string;
  updatedAt: Nullable<string>;
};

type Role<Id> = {
  id: Id;
  name: string;
  description: Nullable<string>;
};

export type AuditLog = {
  id: Uuid;
  objectId: Nullable<Uuid>;
  objectType: AuditLogObject;
  action: AuditLogAction;
  oldValue: Nullable<object>;
  newValue: Nullable<object>;
  createdAt: string;
  creator: {
    id: Uuid;
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    email: string;
  };
};

export type BusinessAccount = {
  id: Uuid;
  name: string;
  type: BusinessAccountType;
  status: BusinessAccountStatus;
  enabledApi: boolean;
};

export type BusinessAccountExtended = BusinessAccount & {
  email: string;
};

export type BusinessAccountApi = {
  id: Uuid;
  webhookUrl: Nullable<string>;
};

export type BusinessAccountBalance = {
  balance: number;
  topUpsPendingBalance: number;
  transfersPendingBalance: number;
  currency: CurrencyCode;
};

export type BusinessAccountFee = {
  feeCommissions: Nullable<FeeCommissionSimple[]>;
  feeGroupId?: Nullable<Uuid>;
};

export type BusinessAccountMember = Client & {
  roles: BusinessAccountRole[];
};

export type BusinessAccountRole = Role<BusinessAccountRoleId>;

export type Client = {
  id: Uuid;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: string;
  phone: Nullable<string>;
  residenceCountryCode: Nullable<string>;
  status: ClientStatus;
};

export type Cost = {
  id: Uuid;
  country: string;
  currency: CurrencyCode;
  transactionFeeType: TransactionFeeType;
  transactionFee: number;
  fxMarkup: number;
  minTransactionFeeAmount: number;
  maxTransactionFeeAmount: number;
  startDate?: Nullable<string>;
  endDate?: Nullable<string>;
  notes: Nullable<string>;
  active: boolean;
  c2c: boolean;
  c2b: boolean;
  b2c: boolean;
  b2b: boolean;
  provider: {
    id: Uuid;
    name: string;
    currency: CurrencyCode;
  };
};

export type Country = {
  code: CountryCode;
  name: string;
};

export type CountryCode = string;

export type Currency = {
  code: CurrencyCode;
  name: string;
  active: boolean;
};

export type CurrencyCode = string;

export type CurrencyRate = {
  id: Uuid;
  baseCurrency: CurrencyCode;
  targetCurrency: CurrencyCode;
  rate: number;
  date: string;
};

export type FeeCommission = Model<{
  feeGroupId?: Nullable<Uuid>;
  name: string;
  level: FeeCommissionLevel;
  transactionFeeType: TransactionFeeType;
  transactionFee: number;
  fxMarkup: number;
  minTransactionFeeAmount: number;
  maxTransactionFeeAmount: number;
  currency: CurrencyCode;
  startDate?: Nullable<string>;
  endDate?: Nullable<string>;
  description: Nullable<string>;
  active: boolean;
}>;

export type FeeCommissionSimple = {
  id: Uuid;
  name: string;
};

export type FeeGroup = Model<{
  name: string;
  description: string;
  assigned: boolean;
}>;

export type Provider = {
  id: Uuid;
  name: string;
  currency: CurrencyCode;
  frequency?: Nullable<ProviderFrequency>;
  minFeeAmount: number;
  notes?: Nullable<string>;
  active: boolean;
  files: UploadedFile[];
};

export type ProviderSimple = Model<{
  name: string;
  currency: CurrencyCode;
  active: boolean;
}>;

export type ReportDailyBalanceItem = {
  startingBalance: number;
  endingBalance: number;
  totalAmount: number;
  totalTransactionFees: number;
  totalFxMarkupFees: number;
  totalProviderFees: number;
  currency: CurrencyCode;
  date: string;
};

export type ReportDailyRemittanceItem = {
  uploadedFiles: number;
  acceptedTransactions: number;
  rejectedTransactions: number;
  remittedAmount: number;
  rejectedAmount: number;
  remittedFees: number;
  currency: CurrencyCode;
  date: string;
};

export type ReportDailyTransactionItem = {
  totalTransactions: number;
  totalAmount: number;
  totalFees: number;
  currency: CurrencyCode;
  date: string;
};

export type Transaction = Model<{
  userId: Uuid;
  clientId: Uuid;
  flowId?: Nullable<Uuid>;
  transferId?: Nullable<Uuid>;
  externalId: Nullable<string>;
  providerReferenceId: Nullable<string>;
  clientReferenceId: Nullable<string>;
  beneficiary?: Nullable<string>;
  amount: number;
  currency: CurrencyCode;
  balance: number;
  previousBalance: number;
  transactionFee: number;
  fxMarkupFee: number;
  providerFee: number;
  fees: number;
  status: TransactionStatus;
  notes?: Nullable<string>;
  statusChangedAt: Nullable<string>;
  client: {
    id: Uuid;
    name: string;
  };
}>;

export type UploadedFile = Model<{
  fileName: string;
  originFileName: string;
  bucketUrl: string;
}>;

export type User = {
  id: Uuid;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: string;
  phone: Nullable<string>;
  roles: UserRole[];
};

export type UserRole = Role<UserRoleId>;
