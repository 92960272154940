import React, { FC } from 'react';

import { formatter } from 'helpers';
import { useLang } from 'hooks';
import { DiffViewer, Flex, Form, Input, Modal, Select, Text } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { AuditLog, AuditLogAction, AuditLogObject } from 'types/models';

import styles from './styles.module.css';

type AuditLogModalProps = ModalBaseProps & {
  log: AuditLog;
};

const AuditLogModal: FC<AuditLogModalProps> = ({
  log,
  open,
  onClose,
}) => {
  const lang = useLang();

  return (
    <Modal
      title={lang.get('auditLog.modal.title')}
      caption={lang.get('auditLog.modal.caption')}
      cancelText={lang.get('common.close')}
      okButtonProps={{ hidden: true }}
      width="lg"
      open={open}
      onCancel={onClose}
    >
      <Form>
        <Form.Columns>
          <Form.Item label={lang.get('auditLog.modal.date.label')}>
            <Input value={formatter.formatDateTime(log.createdAt)} disabled />
          </Form.Item>
          <Form.Item label={lang.get('auditLog.modal.user.label')}>
            <Select
              value={{
                value: log.creator.id,
                label: (
                  <Flex className={styles.user__option} vertical>
                    <Text className={styles.user__option__name}>{formatter.formatName(log.creator.firstName, log.creator.lastName)}</Text>
                    <Text className={styles.user__option__email} type="secondary">{log.creator.email}</Text>
                  </Flex>
                ),
              }}
              disabled
            />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item label={lang.get('auditLog.modal.action.label')}>
            <Select
              value={log.action}
              options={Object.values(AuditLogAction).map((action) => ({
                value: action,
                label: lang.get(`auditLog.actions.${action}`),
              }))}
              disabled
            />
          </Form.Item>
          <Form.Item label={lang.get('auditLog.modal.object.label')}>
            <Select
              value={log.objectType}
              options={Object.values(AuditLogObject).map((object) => ({
                value: object,
                label: lang.get(`auditLog.objects.${object}`),
              }))}
              disabled
            />
          </Form.Item>
        </Form.Columns>
        <DiffViewer
          leftTitle={lang.get('auditLog.modal.oldValue.label')}
          rightTitle={lang.get('auditLog.modal.newValue.label')}
          oldValue={log.oldValue}
          newValue={log.newValue}
        />
      </Form>
    </Modal>
  );
};

export default AuditLogModal;
