import { config } from 'data';
import { userService } from 'services';
import { useQuery } from 'hooks';

const useAllUserRolesQuery = () => useQuery({
  queryKey: [config.USER_ROLES_QUERY_KEY, 'all'],
  queryFn: userService.getRoles,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export default useAllUserRolesQuery;
