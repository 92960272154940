import React, { ChangeEventHandler, FC, useState } from 'react';
import { PresetStatusColorType } from 'antd/es/_util/colors';

import { config, moment } from 'data';
import { formatter, xlsx } from 'helpers';
import { transactionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useTransactionsQuery } from 'hooks/queries';
import { Eye, Table as TableIcon, TopUp } from 'components/icons';
import { Button, DateRangePicker, Flex, Radio, Select, Table, Tag } from 'components/ui';
import { XlsxHeader, XlsxRow } from 'types/common';
import { DateRangePickerChangeHandler, RadioChangeHandler, SelectChangeHandler, TableColumns } from 'types/components';
import { BusinessAccount, Transaction, TransactionStatus, TransactionType } from 'types/models';
import { TransactionsParams } from 'types/services';

import Balance from '../BusinessAccountBalance';
import TableView from '../TableView';
import TransactionModal from '../TransactionModal';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'transactions';

const statusColors: Record<TransactionStatus, PresetStatusColorType> = {
  [TransactionStatus.CANCELED]: 'default',
  [TransactionStatus.COMPLETED]: 'success',
  [TransactionStatus.FAILED]: 'error',
  [TransactionStatus.INSUFFICIENT_FUNDS]: 'error',
  [TransactionStatus.PENDING]: 'processing',
};

type TransactionsViewProps = {
  businessAccount?: BusinessAccount;
};

type TableParams = {
  dateFrom: string;
  dateTo: string;
  search: string;
  status: '' | TransactionStatus;
  type: '' | TransactionType;
};

const initialTableParams: TableParams = {
  dateFrom: '',
  dateTo: '',
  search: '',
  status: '',
  type: '',
};

const TransactionsView: FC<TransactionsViewProps> = ({ businessAccount }) => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<Transaction, TableParams>([config.TRANSACTIONS_QUERY_KEY, businessAccount?.id], initialTableParams);

  const [transaction, setTransaction] = useState<Transaction>();

  const transactionsParams: TransactionsParams = {
    clientId: businessAccount?.id,
    page: table.page,
    dateFrom: table.params.dateFrom || undefined,
    dateTo: table.params.dateTo || undefined,
    search: table.params.search || undefined,
    status: table.params.status || undefined,
    type: table.params.type || undefined,
  };

  const transactionsQuery = useTransactionsQuery(transactionsParams);

  const handleFilterSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => table.setParam('search', event.target.value);

  const handleFilterTypeChange: RadioChangeHandler = (event) => table.setParam('type', event.target.value);

  const handleFilterStatusChange: SelectChangeHandler = (value) => table.setParam('status', value);

  const handleFilterDatesChange: DateRangePickerChangeHandler = (dates) => {
    const [dateFrom, dateTo] = dates ?? [];

    table.setParams({
      dateFrom: dateFrom ? dateFrom.startOf('day').toISOString() : '',
      dateTo: dateTo ? dateTo.endOf('day').toISOString() : '',
    });
  };

  const handleExportClick = async () => {
    try {
      table.setExporting(true);

      const fileName = [
        EXPORT_FILE_NAME,
        businessAccount?.name,
        table.params.dateFrom && moment(table.params.dateFrom).format(config.DATE_RAW_FORMAT),
        table.params.dateTo && moment(table.params.dateTo).format(config.DATE_RAW_FORMAT),
      ].filter(Boolean).join('-');

      const data = await fetchPaginatedResponseFully(transactionService.getTransactions, transactionsParams);

      const headers: XlsxHeader[] = [
        lang.get('transaction.list.date'),
        lang.get('transaction.list.businessAccount'),
        lang.get('transaction.list.currency'),
        lang.get('transaction.list.incoming'),
        lang.get('transaction.list.amount'),
        lang.get('transaction.list.fees'),
        lang.get('transaction.list.outgoing'),
        lang.get('transaction.list.status'),
      ];

      const rows: XlsxRow[] = data.map((transaction) => ({
        date: formatDateColumn(transaction),
        businessAccount: formatBusinessAccountColumn(transaction),
        currency: formatCurrencyColumn(transaction),
        incoming: formatIncomingColumn(transaction),
        amount: formatAmountColumn(transaction),
        fees: formatFeesColumn(transaction),
        outgoing: formatOutgoingColumn(transaction),
        status: formatStatusColumn(transaction),
      }));

      xlsx.exportFile(fileName, headers, rows);
    } finally {
      table.setExporting(false);
    }
  };

  const handleCreateClick = () => {
    setTransaction(undefined);

    modal.open();
  };

  const handleViewClick = (transaction: Transaction) => () => {
    setTransaction(transaction);

    modal.open();
  };

  useTableQuery(table, transactionsQuery);

  const formatDateColumn = (transaction: Transaction) => formatter.formatDateTime(transaction.statusChangedAt ?? transaction.createdAt);

  const formatBusinessAccountColumn = (transaction: Transaction) => transaction.client.name;

  const formatCurrencyColumn = (transaction: Transaction) => transaction.currency;

  const formatIncomingColumn = (transaction: Transaction) => {
    if (transaction.status === TransactionStatus.COMPLETED) {
      return formatter.formatNumber(transaction.previousBalance);
    }

    return '-';
  };

  const formatAmountColumn = (transaction: Transaction) => formatter.formatNumber(transaction.amount);

  const formatFeesColumn = (transaction: Transaction) => formatter.formatNumber(transaction.fees);

  const formatOutgoingColumn = (transaction: Transaction) => {
    if (transaction.status === TransactionStatus.COMPLETED) {
      return formatter.formatNumber(transaction.balance);
    }

    return '-';
  };

  const formatStatusColumn = (transaction: Transaction) => lang.get(`transaction.statuses.${transaction.status}`);

  const columns: TableColumns<Transaction> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('transaction.list.date'),
      render: (_, transaction) => formatDateColumn(transaction),
    }, {
      key: 'businessAccount',
      title: lang.get('transaction.list.businessAccount'),
      hidden: Boolean(businessAccount),
      render: (_, transaction) => <Table.Truncate>{formatBusinessAccountColumn(transaction)}</Table.Truncate>,
    }, {
      key: 'currency',
      title: lang.get('transaction.list.currency'),
      render: (_, transaction) => formatCurrencyColumn(transaction),
    }, {
      className: styles.table__balance,
      key: 'incoming',
      title: lang.get('transaction.list.incoming'),
      render: (_, transaction) => formatIncomingColumn(transaction),
    }, {
      className: styles.table__amount,
      key: 'amount',
      title: lang.get('transaction.list.amount'),
      render: (_, transaction) => formatAmountColumn(transaction),
    }, {
      className: styles.table__balance,
      key: 'fees',
      title: lang.get('transaction.list.fees'),
      render: (_, transaction) => formatFeesColumn(transaction),
    }, {
      className: styles.table__balance,
      key: 'outgoing',
      title: lang.get('transaction.list.outgoing'),
      render: (_, transaction) => formatOutgoingColumn(transaction),
    }, {
      key: 'status',
      title: lang.get('transaction.list.status'),
      render: (_, transaction) => (
        <Tag color={statusColors[transaction.status]}>
          {formatStatusColumn(transaction)}
        </Tag>
      ),
    }, {
      key: 'actions',
      title: lang.get('common.list.actions'),
      render: (_, transaction) => (
        <Table.Actions
          buttons={[{
            title: lang.get('common.view'),
            icon: <Eye />,
            onClick: handleViewClick(transaction),
          }]}
        />
      ),
    },
  ];

  return (
    <TableView
      title={
        businessAccount
          ? lang.get('transaction.list.businessAccountTitle', { name: businessAccount.name })
          : lang.get('transaction.list.title')
      }
      caption={businessAccount && <Balance businessAccountId={businessAccount.id} />}
      actions={(
        <Flex gap="small" align="center" wrap="wrap">
          <Button
            type="default"
            icon={<TableIcon />}
            loading={table.exporting}
            disabled={!table.data.length}
            onClick={handleExportClick}
          >
            {lang.get('common.exportXlsx')}
          </Button>
          <Button icon={<TopUp />} onClick={handleCreateClick}>
            {lang.get('transaction.list.create')}
          </Button>
        </Flex>
      )}
      filters={(
        <Flex gap="small" align="center" wrap="wrap">
          <TableView.Search
            defaultValue={table.params.search}
            onChange={handleFilterSearchChange}
          />
          <Radio.Group
            defaultValue={table.params.type}
            onChange={handleFilterTypeChange}
          >
            <Radio.Button value="">{lang.get('transaction.types.all')}</Radio.Button>
            {Object.values(TransactionType).map((type) => (
              <Radio.Button key={type} value={type}>
                {lang.get(`transaction.types.${type.toLowerCase()}`)}
              </Radio.Button>
            ))}
          </Radio.Group>
          <Select
            defaultValue={table.params.status}
            options={[{
              value: '',
              label: lang.get('transaction.statuses.all'),
            }].concat(Object.values(TransactionStatus).map((status) => ({
              value: status,
              label: lang.get(`transaction.statuses.${status.toLowerCase()}`),
            })))}
            popupMatchSelectWidth={false}
            onChange={handleFilterStatusChange}
          />
          <DateRangePicker
            defaultValue={[
              table.params.dateFrom ? moment(table.params.dateFrom) : null,
              table.params.dateTo ? moment(table.params.dateTo) : null,
            ]}
            maxDate={moment()}
            allowEmpty
            onChange={handleFilterDatesChange}
          />
        </Flex>
      )}
    >

      <Table<Transaction>
        columns={columns}
        dataSource={table.data}
        pagination={table.pagination}
        rowKey={(transaction) => transaction.id}
        loading={transactionsQuery.isFetching}
        clickable
        onRow={(transaction) => ({ onClick: handleViewClick(transaction) })}
        onChange={table.onChange}
      />

      <TransactionModal
        businessAccount={businessAccount}
        transaction={transaction}
        open={modal.opened}
        onClose={modal.close}
      />

    </TableView>
  );
};

export default TransactionsView;
