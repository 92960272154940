const config = {
  IS_PRODUCTION: process.env.NODE_ENV === 'production',
  NAME: process.env.REACT_APP_NAME as string,

  API_URL: process.env.REACT_APP_API_URL as string,
  API_TIMEOUT: 5000, // 5 seconds

  AUTH_KEEP_ALIVE_INTERVAL: 60000, // 1 minute
  AUTH_STORAGE_KEY: 'auth',

  DEFAULT_COUNTRY: 'US',
  DEFAULT_CURRENCY: 'USD',
  DEFAULT_LOCALE: 'en',
  DEFAULT_PAGINATION_LIMIT: 7,

  DATE_FORMAT: 'MMM D, YYYY',
  DATE_RAW_FORMAT: 'YYYY-MM-DD',
  DATE_TIME_FORMAT: 'MMM D, YYYY LT',

  THEME_MESSAGE_MAX_COUNT: 3,
  THEME_NOTIFICATION_DURATION: 5, // 5 seconds
  THEME_NOTIFICATION_MAX_COUNT: 3,

  CURRENCY_RATE_MIN: 0.000001,
  CURRENCY_RATE_MAX: 1000000,
  CURRENCY_RATE_PRECISION: 6,
  EMAIL_MAX_LENGTH: 50,
  FILE_MAX_SIZE: 5242880, // 5 MB
  FEE_COMMISSION_MIN: 0,
  FEE_COMMISSION_MAX: 1000000000,
  NAME_MAX_LENGTH: 50,
  PASSWORD_CURRENT_MIN_LENGTH: 2,
  PASSWORD_MIN_LENGTH: 8,
  PASSWORD_MAX_LENGTH: 50,
  PERCENT_MIN: 0,
  PERCENT_MAX: 100,
  PHONE_MAX_LENGTH: 20,
  PROVIDER_FEE_MIN: 0,
  PROVIDER_FEE_MAX: 1000000000,
  STRING_MIN_LENGTH: 3,
  STRING_MAX_LENGTH: 255,
  STRING_MD_MAX_LENGTH: 100,
  TEXT_MIN_LENGTH: 8,
  TEXT_MAX_LENGTH: 1000,
  TOP_UP_MIN: 0.01,
  TOP_UP_MAX: 1000000000,
  VERIFICATION_CODE_LENGTH: 6,
  WEBSITE_MAX_LENGTH: 255,

  AUDIT_LOGS_QUERY_KEY: 'audit-logs',
  AUTH_CHALLENGE_QUERY_KEY: 'auth-challenge',
  BUSINESS_ACCOUNTS_QUERY_KEY: 'business-accounts',
  BUSINESS_ACCOUNT_QUERY_KEY: 'business-account',
  BUSINESS_ACCOUNT_API_QUERY_KEY: 'business-account-api',
  BUSINESS_ACCOUNT_BALANCE_QUERY_KEY: 'business-account-balance',
  BUSINESS_ACCOUNT_CURRENCIES_QUERY_KEY: 'business-account-currencies',
  BUSINESS_ACCOUNT_DAILY_BALANCE_QUERY_KEY: 'business-account-daily-balance',
  BUSINESS_ACCOUNT_DAILY_TRANSACTIONS_QUERY_KEY: 'business-account-daily-transactions',
  BUSINESS_ACCOUNT_FEE_QUERY_KEY: 'business-account-fee',
  BUSINESS_ACCOUNT_MEMBERS_QUERY_KEY: 'business-account-members',
  BUSINESS_ACCOUNT_ROLES_QUERY_KEY: 'business-account-roles',
  CLIENTS_QUERY_KEY: 'clients',
  COSTS_QUERY_KEY: 'costs',
  COUNTRIES_QUERY_KEY: 'countries',
  CURRENCIES_QUERY_KEY: 'currencies',
  CURRENCY_RATES_QUERY_KEY: 'currency-rates',
  DAILY_REMITTANCE_QUERY_KEY: 'daily-remittance',
  FEE_COMMISSIONS_QUERY_KEY: 'fee-commissions',
  FEE_GROUPS_QUERY_KEY: 'fee-groups',
  PROFILE_QUERY_KEY: 'profile',
  PROVIDERS_QUERY_KEY: 'providers',
  PROVIDER_QUERY_KEY: 'provider',
  TRANSACTIONS_QUERY_KEY: 'transactions',
  USERS_QUERY_KEY: 'users',
  USER_QUERY_KEY: 'user',
  USER_ROLES_QUERY_KEY: 'user-roles',
};

export default config;
