import React, { createContext, FC, ReactNode, useMemo, useState } from 'react';

import { config } from 'data';
import { lang } from 'helpers';
import { Locale } from 'types/locales';

type ContextValue = {
  locale: Locale;
  has: typeof lang.has;
  get: typeof lang.get;
  choice: typeof lang.choice;
};

type ProviderProps = {
  children: ReactNode;
};

const LangContext = createContext<ContextValue>({
  locale: config.DEFAULT_LOCALE,
  has: () => false,
  get: () => '',
  choice: () => '',
});

const LangProvider: FC<ProviderProps> = ({ children }) => {
  const [locale] = useState(config.DEFAULT_LOCALE);

  const value = useMemo(() => ({
    locale,
    has: lang.has,
    get: lang.get,
    choice: lang.choice,
  }), [locale]);

  return (
    <LangContext.Provider value={value}>
      {children}
    </LangContext.Provider>
  );
};

const Lang = {
  Context: LangContext,
  Provider: LangProvider,
};

export default Lang;
